<template>
  <div class="c-chat">
    <Header />
    <div class="c-chat__body">
      <div class="chat-container">
        <div class="card card-bordered">
          <div class="card-header">
            <h4 class="card-title">
              <img
                :src="toUser && toUser.image ? imagePath + toUser.image : 'https://img.icons8.com/color/36/000000/administrator-male.png'"
                class="avatar"
                alt="..."
              >
              <div class="text-chat">{{ toUser ? toUser.name : '' }}</div>
            </h4>
            <a class="btn btn-xs btn-secondary" href="#" data-abc="true">X</a>
          </div>
          <div class="ps-container ps-theme-default ps-active-y" id="chat-content" style="overflow-y: scroll !important; height:400px !important;">

            <div
              :key="index"
              v-for="(message, index) in allMessages"
            >
              <div
                class="media media-chat"
                v-if="message.to_user_id === user.id"
              >
                <img
                  :src="toUser && toUser.image ? imagePath + toUser.image : 'https://img.icons8.com/color/36/000000/administrator-male.png'"
                  class="avatar"
                  alt="..."
                >
                <div class="media-body">
                  <p>{{ message.message }}</p>
                  <p class="meta"><time datetime="2018">{{ getTime(message.created_at) }}</time></p>
                </div>
              </div>
              <div
                class="media media-chat media-chat-reverse"
                v-else
              >
                <div class="media-body">
                  <p>{{ message.message }}</p>
                  <p class="meta"><time datetime="2018">{{ getTime(message.created_at) }}</time></p>
                </div>
              </div>
            </div>

            <div class="ps-scrollbar-x-rail" style="left: 0px; bottom: 0px;">
              <div class="ps-scrollbar-x" tabindex="0" style="left: 0px; width: 0px;"></div>
            </div>
            <div class="ps-scrollbar-y-rail" style="top: 0px; height: 0px; right: 2px;">
              <div class="ps-scrollbar-y" tabindex="0" style="top: 0px; height: 2px;"></div>
            </div>
          </div>
          <div class="publisher bt-1 border-light">
            <img
              class="avatar avatar-xs"
              :src="user && user.image ? imagePath + user.image : 'https://img.icons8.com/color/36/000000/administrator-male.png'"
              alt="...">
            <textarea
              class="publisher-input"
              type="text"
              placeholder="Write something"
              v-model="message"
            >
            </textarea>
            <span class="publisher-btn file-group">
              <i class="fa fa-paperclip file-browser"></i>
              <input type="file">
            </span>
            <a class="publisher-btn" href="#" data-abc="true"><i class="fa fa-smile"></i></a>
            <a
              @click="onSendMessage"
              class="publisher-btn text-info"
              data-abc="true"
            >
              <i class="el-icon-top-right"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Pusher from 'pusher-js'
import Header from '@/components/Header'
import Footer from '@/components/Footer'

import userMixin from '@/mixins/user'
import messageMixin from '@/mixins/messages'

Pusher.logToConsole = false

export default {
  created () {
    const toUserId = this.$route.params.id

    this.getUser(toUserId).then((res) => {
      this.toUser = res
    })
    this.getAllMessages()
    this.subscribe()
  },

  components: {
    Header,
    Footer
  },

  computed: {
    imagePath () {
      return process.env.VUE_APP_API_IMAGE_PATH
    }
  },

  data () {
    return {
      message: '',
      allMessages: [],
      toUser: null
    }
  },

  methods: {
    subscribe () {
      const pusher = new Pusher('4b1c61858ff8ed28adc6', {
        cluster: 'ap2',
        encrypted: false
      })
      pusher.subscribe('chat')
      pusher.bind('my-event', data => {
        this.allMessages.push(data.message)
      })
    },

    onSendMessage () {
      this.createMessage({
        from_user_id: this.user.id,
        to_user_id: this.$route.params.id,
        message: this.message
      }).then(() => {
        this.getAllMessages()
        this.message = null
      })
    },

    getAllMessages () {
      const toUserId = this.$route.params.id

      this.getMessages({
        from_user_id: this.user.id,
        to_user_id: Number(toUserId)
      }).then((res) => {
        this.allMessages = res
      })
    },

    getTime (dateTime) {
      const today = new Date(dateTime)
      return today.getHours() + ':' + today.getMinutes()
    }
  },

  mixins: [messageMixin, userMixin],

  mounted () {
    const messageId = this.$route.params.messageId

    if (messageId) {
      setTimeout(() => {
        this.seenMessage({
          id: messageId
        })
      }, 3000)
    }
  },

  name: 'Chat',

  watch: {
    $route (to, from) {
      const toUserId = this.$route.params.id

      this.getUser(toUserId).then((res) => {
        this.toUser = res
      })
      this.getAllMessages()
      this.subscribe()
    }
  }
}
</script>

<style lang="scss" scoped>
.chat {
  list-style: none;
  margin: 0;
  padding: 0;
}

.chat li {
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 1px dotted #B3A9A9;
}

.chat li .chat-body p {
  margin: 0;
  color: #777777;
}

.panel-body {
  overflow-y: scroll;
  height: 350px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #F5F5F5;
}

::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #555;
}
.chat-container{ width: 380px; margin: 0 auto; font-family: 'Open Sans', sans-serif;}
.card-bordered {
  border: 1px solid #ebebeb
}

.card {
  border: 0;
  border-radius: 0px;
  margin-bottom: 30px;
  -webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.03);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.03);
  -webkit-transition: .5s;
  transition: .5s
}

.padding {
  padding: 3rem !important
}

body {
  background-color: #f9f9fa
}

.card-header:first-child {
  border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
  background: #003895;
}

.card-header {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px 10px;
  background-color: transparent;
  border-bottom: 1px solid rgba(77, 82, 89, 0.07)
}

.card-header .card-title {
  margin:10px 0;
  padding: 0;
  border: none;
  color:#fff;
}

h4.card-title {
  font-size: 17px;
  color: #fff;
}

.card-title strong{
  color: #fff;
}

.card-header>*:last-child {
  margin-right: 0
}

.card-header>* {
  margin-left: 8px;
  margin-right: 8px
}

.btn-secondary {
  color: #4d5259 !important;
  background-color: #003895;
  border-color: #e4e7ea;
  color: #fff !important;
  font-size: 15px;
}

.btn-xs {
  font-size: 15px;
  padding: 2px 8px;
  line-height: 18px;
  text-decoration: none;
}

.btn-xs:hover {
  color: #fff !important
}

.card-title {
  font-family: Roboto, sans-serif;
  font-weight: 300;
  line-height: 1.5;
  margin-bottom: 0;
  padding: 15px 20px;
  border-bottom: 1px solid rgba(77, 82, 89, 0.07)
}

.text-chat{
  padding-left: 45px;
  margin-top: -35px;
}

.ps-container {
  position: relative
}

.ps-container {
  -ms-touch-action: auto;
  touch-action: auto;
  overflow: hidden !important;
  -ms-overflow-style: none
}

.media-chat {
  padding-right: 64px;
  margin-bottom: 0;
  background: #fff;
}

.media {
  padding: 16px 12px;
  -webkit-transition: background-color .2s linear;
  transition: background-color .2s linear
}

.media .avatar {
  flex-shrink: 0
}

.avatar {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  border-radius: 100%;
  background-color: #f5f6f7;
  color: #8b95a5;
  text-transform: uppercase
}

.media-chat .media-body {
  -webkit-box-flex: initial;
  flex: initial;
  display: table
}

.media-body {
  min-width: 0
}

.media-chat .media-body p {
  position: relative;
  padding: 6px 8px;
  margin: 4px 0;
  background-color: #f5f6f7;
  border-radius: 3px;
  font-weight: 100;
  color: #716d6d;
}

.media>* {
  margin: 0 8px
}

.media-chat .media-body p.meta {
  background-color: transparent !important;
  padding: 0;
  opacity: .8
}

.media-meta-day {
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 0;
  color: #8b95a5;
  opacity: .8;
  font-weight: 400
}

.media {
  padding: 16px 12px;
  -webkit-transition: background-color .2s linear;
  transition: background-color .2s linear
}

.media-meta-day::before {
  margin-right: 16px
}

.media-meta-day::before,
.media-meta-day::after {
  content: '';
  -webkit-box-flex: 1;
  flex: 1 1;
  border-top: 1px solid #ebebeb
}

.media-meta-day::after {
  content: '';
  -webkit-box-flex: 1;
  flex: 1 1;
  border-top: 1px solid #ebebeb
}

.media-meta-day::after {
  margin-left: 16px
}

.media-chat.media-chat-reverse {
  padding-right: 12px;
  padding-left: 64px;
  text-align: -webkit-right;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  flex-direction: row-reverse
}

.media-chat {
  padding-right: 64px;
  margin-bottom: 0
}

.media {
  padding: 16px 12px;
  -webkit-transition: background-color .2s linear;
  transition: background-color .2s linear
}

.media-chat.media-chat-reverse .media-body p {
  float: right;
  clear: right;
  background-color: #48b0f7;
  color: #fff
}

.media-chat .media-body p {
  position: relative;
  padding: 6px 8px;
  margin: 4px 0;
  background-color: #f5f6f7;
  border-radius: 3px
}

.border-light {
  border-color: #f1f2f3 !important
}

.bt-1 {
  border-top: 1px solid #ebebeb !important
}

.publisher {
  position: relative;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 12px 20px;
  background-color: #fff;
}

.publisher>*:first-child {
  margin-left: 0
}

.publisher>* {
  margin: 0 8px
}

.publisher-input {
  -webkit-box-flex: 1;
  flex-grow: 1;
  border: none;
  outline: none !important;
  background-color: #ccc;
  border-radius: 5Px;
  padding: 5PX;
}

button,
input,
optgroup,
select,
textarea {
  font-family: Roboto, sans-serif;
  font-weight: 300
}

.publisher-btn {
  background-color: transparent;
  border: none;
  color: #8b95a5;
  font-size: 16px;
  cursor: pointer;
  overflow: -moz-hidden-unscrollable;
  -webkit-transition: .2s linear;
  transition: .2s linear
}

.file-group {
  position: relative;
  overflow: hidden
}

.publisher-btn {
  background-color: transparent;
  border: none;
  color: #cac7c7;
  font-size: 16px;
  cursor: pointer;
  overflow: -moz-hidden-unscrollable;
  -webkit-transition: .2s linear;
  transition: .2s linear
}

.file-group input[type="file"] {
  position: absolute;
  opacity: 0;
  z-index: -1;
  width: 20px
}

.text-info {
  color: #48b0f7 !important
}
</style>
